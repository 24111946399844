/* eslint-disable react/no-unknown-property */
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 brawndo_can.gltf 
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export default function Model(props) {
  const { nodes, materials } = useGLTF("/brawndo_can.gltf");
  const group = useRef();

  useFrame(() => {
    group.current.rotation.y -= 0.02;
  });

  return (
    <group
      {...props}
      dispose={null}
      position={[0, -0.5, 0]}
      scale={10}
      ref={group}
    >
      <group>
        <mesh
          geometry={nodes.Circle012.geometry}
          material={materials.tornado_label}
        />
        <mesh
          geometry={nodes.Circle012_1.geometry}
          material={materials.brushed_aluminium}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/brawndo_can.gltf");
