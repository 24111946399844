import React from "react";
import Brawndo3DCan from "../Brawndo3DCan";
import { FaAngleDown } from "react-icons/fa6";
import { useScroll } from "../ScrollContext/ScrollContext";

const Intro: React.FC = () => {
  const { targetRef } = useScroll();

  const scrollToTarget = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div>
      <Brawndo3DCan />
      <div className="flex justify-center items-center">
        <div className="relative -top-20">
          <button
            onClick={scrollToTarget}
            className="text-white text-3xl font-bold text-center mb-8 uppercase md:text-5xl text-center"
          >
            <FaAngleDown />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Intro;
