import React from "react";
import Navbar from "./components/Navbar";
import Intro from "./components/Intro";
import Narrative from "./components/Narrative";
// import Tokenomics from "./components/Tokenomics";
// import Info from "./components/Info";
import HowToBuy from "./components/HowToBuy";
import ScrollProvider from "./components/ScrollContext/ScrollContext";
// import Footer from "./components/Footer";

function App() {
  return (
    <ScrollProvider>
      <div className="App h-screen w-screen overflow-hidden bg-[url('./assets/background.png')] bg-cover">
        <Navbar />
        <div className="h-full overflow-auto">
          <div className="backdrop-blur-md bg-black/20">
            <Intro />
            <Narrative />
            <HowToBuy />
          </div>
        </div>
      </div>
    </ScrollProvider>
  );
}

export default App;
