import React, { createContext, useContext, useRef, ReactNode } from "react";

interface ScrollContextType {
  targetRef: React.RefObject<HTMLDivElement>;
}

const ScrollContext = createContext<ScrollContextType | undefined>(undefined);

export const useScroll = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error("useScroll doit être utilisé au sein d’un ScrollProvider");
  }
  return context;
};

export const ScrollProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const targetRef = useRef<HTMLDivElement>(null);

  return (
    <ScrollContext.Provider value={{ targetRef }}>
      {children}
    </ScrollContext.Provider>
  );
};

export default ScrollProvider;
