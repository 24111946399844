import React from "react";
import { Suspense } from "react";

import Model from "./Brawndo_can";
import { Canvas } from "@react-three/fiber";
import { Environment } from "@react-three/drei";

const Brawndo3DCan = () => {
	return (
		<div className='flex h-screen w-screen'>
			<Canvas camera={{ position: [0, 10, 50], zoom: 30 }}>
				<Suspense fallback={null}>
					<ambientLight intensity={0.3} />
					<Model />
				</Suspense>
				<Environment preset='city' />
			</Canvas>
		</div>
	);
};

export default Brawndo3DCan;
