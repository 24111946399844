import React from "react";
import { useScroll } from "../ScrollContext/ScrollContext";
import gif1 from "../../assets/gif/gif1.gif";
import gif2 from "../../assets/gif/gif2.gif";
import gif3 from "../../assets/gif/gif3.gif";
import gif4 from "../../assets/gif/gif4.gif";
import gif5 from "../../assets/gif/gif5.gif";
import gif6 from "../../assets/gif/gif6.gif";

const Narrative: React.FC = () => {
  const { targetRef } = useScroll();
  const gifs = [gif1, gif2, gif3, gif4, gif5, gif6];

  return (
    <div
      ref={targetRef}
      className="target-element flex flex-col items-center text-center font-bold text-white w-10/12 m-auto	"
    >
      <h1 className=" text-3xl  mb-8 uppercase md:text-5xl  mt-20">
        What's brawndo ?
      </h1>
      <div>
        <h2 className="text-xl  uppercase md:text-2xl mb-2">
          🚀💦The Thirst Mutilator for Your Wallet!💦🚀
        </h2>
        <p className=" text-sm md:text-[16px] mb-8">
          Are you tired of your crypto wallet looking like a deserted wasteland,
          parched and devoid of juicy gains?
          <br /> Say no more! <br />
          Let’s pump some $BRAWNDO into that dry scene and watch it bloom with
          the lush green of profits. Why? <br />
          Because $BRAWNDO's got what wallets crave. It's got electrolytes. It's
          got blockchain. It's got that zesty, effervescent buzz that'll make
          your digital assets gush like never before.
        </p>
        <h2 className=" text-xl uppercase md:text-2xl mb-2">
          🌊 Dive into the Solana Sea with Us! 🌊
        </h2>
        <p className=" text-sm md:text-[16px] mb-8">
          In the barren desert of traditional finance, where yield is as scarce
          as rain, $BRAWNDO emerges as an oasis of hope. Our team of degen devs
          has tapped into the source of all liquidity, concocting a memecoin so
          potent, it hydrates your holdings with unprecedented growth.
        </p>
        <h2 className=" text-xl uppercase md:text-2xl mb-2">
          👾 DeFi Degen? Welcome Home! 👾
        </h2>
        <p className=" text-sm md:text-[16px] mb-8">
          If your idea of a good time involves aping into projects at 3 AM,
          knowing full well the absurdity of the crypto cosmos, then $BRAWNDO is
          your kind of drink. <br />
          It’s not just a memecoin; it’s a lifestyle, a statement, a testament
          to the undying spirit of the blockchain adventurer.
        </p>
        <h2 className=" text-xl uppercase md:text-2xl mb-2">
          💥 Features of $BRAWNDO 💥
        </h2>
        <p className=" text-sm md:text-[16px] mb-8">
          Electrolyte-Enhanced Earnings: Because who said money doesn't grow on
          chains?
          <br />
          Drip Drops: Exclusive airdrops for the thirstiest wallets.
          <br />
          {/* Liquidity Pools: Dive in; the water's fine, and the APR is even finer.
          <br />
          Staking Stunts: Lock up your $BRAWNDO and watch your digits do the
          Harlem Shake. */}
        </p>
        <h2 className=" text-xl uppercase md:text-2xl mb-2">
          🔥 Join the Hydration Nation! 🔥
        </h2>
        <p className=" text-sm md:text-[16px] mb-8">
          This is not just another token on the blockchain. This is the memecoin
          that could only exist on Solana, where speed meets hilarity, and
          finance meets fun.
          <br />
          So, are you ready to quench your thirst for the absurd, the lucrative,
          and the downright degen? <br />
          Don’t let your wallet turn to dust. Give it the $BRAWNDO boost and
          ride the wave of hydration to the moon. <br />
          Because remember, in the world of $BRAWNDO, it’s not just about the
          gains; it’s about making the blockchain bizarre again. <br />
          <br />Join us in achieving ATH - ALL TIME HYDRATION!
        </p>
      </div>
      <div className="grid grid-cols-3 gap-4 p-4">
        {gifs.map((gif, index) => (
          <div key={index} className="w-full">
            <img src={gif} alt={`GIF ${index + 1}`} className="w-full h-auto" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Narrative;
