import React, { useState } from "react";
import {
  FaXTwitter,
  FaDiscord,
  FaTelegram,
  FaChartLine,
} from "react-icons/fa6";

const Menu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const open = () => {
    console.log("lalala");
    setIsOpen(!isOpen);
  };
  return (
    <div className="fixed top-0 right-0 m-5">
      <button
        className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 border-white"
        onClick={() => open()}
      >
        <svg
          className="fill-current h-3 w-3 text-gray-800"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>

      {/* Menu Items */}
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 shadow-xl py-2 px-2 rounded-lg border-2">
          <a
            href="https://birdeye.so/token/DUuqTfp6CxceXiuaqeHoSwH2NoYegPBav8NmcE1azHQU?chain=solana"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white font-bold text-lg uppercase cursor-pointer flex gap-2 items-center"
          >
            <FaChartLine size={24} /> Birdeye
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            className="text-white font-bold text-lg uppercase cursor-pointer flex gap-2 items-center"
            href="https://twitter.com/BrawndoSOL"
          >
            <FaXTwitter size={24} /> Twitter
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="text-white font-bold text-lg uppercase cursor-pointer flex gap-2 items-center"
            href="https://discord.gg/YVHKECsWP4"
          >
            <FaDiscord size={24} /> Discord
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="text-white font-bold text-lg uppercase cursor-pointer flex gap-2 items-center"
            href="https://t.me/+tYLG4g2esI1iZWQ5"
          >
            <FaTelegram size={24} /> Telegram
          </a>
        </div>
      )}
    </div>
  );
};

export default Menu;
