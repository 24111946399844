import React, { useEffect, useState } from "react";
import { Copy } from "lucide-react";

const HowToBuy: React.FC = () => {
  const [copySuccess, setCopySuccess] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const scriptId = "jupiter-script";

    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://terminal.jup.ag/main-v2.js";
      script.async = true;

      script.onload = () => {
        try {
          console.log("Script chargé !");
          if (window.Jupiter) {
            console.log("Initialisation de Jupiter...");
            window.Jupiter.init({
              displayMode: "integrated",
              integratedTargetId: "jupiter-widget",
              endpoint:
                "https://global.rpc.hellomoon.io/23af2cda-6d3d-4f1b-be90-d8242ef54674",
              strictTokenList: false,

              formProps: {
                fixedOutputMint: true,
                initialOutputMint:
                  "DUuqTfp6CxceXiuaqeHoSwH2NoYegPBav8NmcE1azHQU",
              },
            });
          }
        } catch (error) {
          console.error("Jupiter initialization failed:", error);
        }
      };
      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>; // Correction ici
    if (showMessage) {
      timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000); // Disparaît après 3 secondes
    }
    return () => clearTimeout(timer);
  }, [showMessage]);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopySuccess("You've just hydrated your clipboard");
        setShowMessage(true);
      },
      (err) => {
        console.error("Error copying:", err);
      }
    );
  };

  return (
    <div
      id="how-to-buy"
      className="flex flex-col items-center justify-center mt-10"
    >
      <h1 className="text-white text-3xl font-bold text-center mb-8 uppercase md:text-5xl">
        How to Buy
      </h1>
      <div
        className="flex items-center cursor-pointer text-white font-bold text-sm md:text-[16px]"
        onClick={() =>
          copyToClipboard("DUuqTfp6CxceXiuaqeHoSwH2NoYegPBav8NmcE1azHQU")
        }
      >
        DUuqTfp6CxceXiuaqeHoSwH2NoYegPBav8NmcE1azHQU
        <Copy className="ml-2 h-3 w-3 md:w-4 md:h-4" />
      </div>
      <div
        className={`text-white text-sm mt-2 transition-opacity duration-1000 ease-in-out ${
          showMessage ? "opacity-100" : "opacity-0"
        }`}
      >
        {copySuccess || "Placeholder"}
      </div>
      <div className="w-full max-w-4xl">
        <div id="jupiter-widget"></div>
      </div>
    </div>
  );
};

export default HowToBuy;
