import React from "react";
import Logo from "../../assets/branwdo_logo.png";
import Menu from "../Menu";

const Navbar = () => {
  return (
    <div className="w-full px-8 py-4 absolute top-0 left-0 z-10">
      <div className="flex items-center justify-between h-full">
        <img src={Logo} alt="Brawndo logo" width={104} />
        <Menu />
      </div>
    </div>
  );
};

export default Navbar;
